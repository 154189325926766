<template>
  <div class="SituationJuridique" >
    <validationCartouche
      :hasCedantValidated="situationJuridique.has_cedant_validated"
      :hasRepreneurValidated="situationJuridique.has_repreneur_validated"
    />


    <div class="container-fluid">

      <macroStepper
        :step="1"
      />

      <h1 class="gavc-h1 text-center">1. Situation juridique des parties signataires </h1>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$router.push(`/pourparler/${situationJuridique.audit.pourparler_id}`)"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente (Pourparlers)
        </button>
        <button
          v-if="situationJuridique.has_repreneur_validated && situationJuridique.has_cedant_validated"
          class="gavc-btn"
          @click="$router.push(`/audit/entreprise-cedee/${situationJuridique.audit.id}`)"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>

      <div class="row">
        <div class="col-12 col-xl-6 pl-4 pr-4">
          <h2 class="gavc-h2 gavc-text-green text-center mb-4">Le cédant
            <span v-if="userGroupContains(['CEDANT'])"> (à compléter)</span>
            <span v-if="userGroupContains(['REPRENEUR'])"> (à valider)</span>
          </h2>


          <div v-if="userGroupContains(['REPRENEUR'])">
            <div class="gavc-field">
              <label class="gavc-label" for="situationJuridique-repreneur_comment-input">
                <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
              </label>
              <textarea
                class="gavc-textarea"
                id="situationJuridique-repreneur_comment-input"
                maxlength="1000"
                rows="5"
                @change="modifySituationJuridique"
                placeholder="Je laisse mon commentaire au cédant"
                type="text"
                v-model="situationJuridique.repreneur_comment">
              </textarea>
            </div>
          </div>

          <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="situationJuridique.repreneur_comment">
            <strong> Le repreneur vous a laissé un commentaire </strong> <br>
            <span style="white-space: pre-line"> {{situationJuridique.repreneur_comment}} </span>
          </div>

          <auditExplanation
            v-if="userGroupContains(['CEDANT'])"
            :isCedant="true"
            :isRepreneur="false"
          />

          <p class="gavc-p" v-else>
              Veuillez trouver ci-dessous les informations renseignées et documents
              chargés du cédant
          </p>


          <div v-if="!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique">
            <h3 class="gavc-h3 gavc-text-green mt-4"> La société détenant l'entreprise cédée </h3>
            <p>
              Documents à charger : <strong>Kbis et statuts de la société détenant l'entreprise cédée</strong>
            </p>
            <auditItem
              :disabled="!cedantCanModified"
              @document-deleted="deleteAuditItem($event, 'situationJuridique')"
              @document-download="downloadAuditDocument($event, 'situationJuridique')"
              @document-uploaded="createAuditDocument($event, 'KBIS_STATUS', situationJuridique.id, 'situationJuridique')"
              :items="filterDocuments('KBIS_STATUS', 'CEDANT')"
              @item-validated="validateAuditItem($event, 'situationJuridique', true)"
              @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
              @justificatif-added="createAuditItemWithoutDocument($event, 'KBIS_STATUS', situationJuridique.id, 'situationJuridique')"
              type="KBIS_STATUS"
            />
          </div>

          <h3 v-if="!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique" class="gavc-h3 gavc-text-green mt-4"> Le dirigeant </h3>
          <h3 v-else class="gavc-h3 gavc-text-green mt-4"> Le cédant </h3>

          <p>
            Documents à charger : <strong>Carte d'identité ou passeport valide</strong>
            <span v-if="!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique"> du dirigeant (de la société détenant l'entreprise cédée)</span>
            <span v-else> du cédant</span>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'situationJuridique')"
            @document-download="downloadAuditDocument($event, 'situationJuridique')"
            @document-uploaded="createAuditDocument($event, 'CNI', situationJuridique.id, 'situationJuridique')"
            @item-validated="validateAuditItem($event, 'situationJuridique', true)"
            @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
            :items="filterDocuments('CNI', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'CNI', situationJuridique.id, 'situationJuridique')"
            type="CNI"
          />

          <div class="row mt-4">
            <div class="col-12 col-md-6">
              <div
                class="gavc-field"
                v-bind:class="{ 'is-error': $v.situationJuridique.cedant_email.$dirty ? $v.situationJuridique.cedant_email.$error : null  }">
                <label class="gavc-label" for="situationJuridique-cedant_email-group">
                  Email :
                  <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique-cedant_email-group"
                  maxlength="150"
                  @input="$v.situationJuridique.cedant_email.$touch()"
                  type="email"
                  placeholder="jon@donym.fr"
                  v-model="situationJuridique.cedant_email"
                  :disabled="!cedantCanModified"
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="gavc-field"
                v-bind:class="{ 'is-error': $v.situationJuridique.cedant_address.$dirty ? $v.situationJuridique.cedant_address.$error : null  }">
                <label class="gavc-label" for="cedant-edit-profile-address-group">
                    Adresse
                    <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique.cedant_address-group"
                  maxlength="250"
                  @input="$v.situationJuridique.cedant_address.$touch()"
                  type="text"
                  v-model="situationJuridique.cedant_address"
                  :disabled="!cedantCanModified"
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="gavc-field mt-3">
                <label class="gavc-label" for="situationJuridique.cedant_birthdate-input">
                  Date de naissance
                  <span class="gavc-required"> * </span>
                </label>
                <div class="gavc-field gavc-field--icon">
                  <input
                    @input="$v.situationJuridique.cedant_birthdate.$touch()"
                    class="gavc-input gavc-input--icon gavc-input--calendar"
                    id="situationJuridique.cedant_birthdate-input"
                    type="date"
                    v-model="situationJuridique.cedant_birthdate"
                    :disabled="!cedantCanModified"
                  >
                  <i class="gavc-icon gavc-icon-calendar" ></i>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="gavc-field mt-3"
                v-bind:class="{ 'is-error': $v.situationJuridique.cedant_birthplace.$dirty ? $v.situationJuridique.cedant_birthplace.$error : null  }">
                <label class="gavc-label" for="situationJuridique.cedant_birthplace-group">
                    Lieu de naissance
                    <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique.cedant_birthplace-group"
                  maxlength="250"
                  @input="$v.situationJuridique.cedant_birthplace.$touch()"
                  type="text"
                  v-model="situationJuridique.cedant_birthplace"
                  :disabled="!cedantCanModified"
                >
              </div>
            </div>
          </div>

          <div v-if="!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique">
            <p class="gavc-label mt-3">
              Vous disposez d’un mandat de l’instance statutaire compétente ou disposez du pouvoir statutaire pour procéder à la présente cession ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input :value="true" type="radio" id="situationJuridique.cedant_dirigeant_has_mandat-input-1"
                         name="situationJuridique.cedant_dirigeant_has_mandat-input-1" v-model="situationJuridique.cedant_dirigeant_has_mandat"
                         :disabled="!cedantCanModified"
                         @change="modifySituationJuridique">
                  <label class="gavc-label" for="situationJuridique.cedant_dirigeant_has_mandat-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input :value="false" type="radio" id="situationJuridique.cedant_dirigeant_has_mandat-input-2"
                         name="situationJuridique.cedant_dirigeant_has_mandat-input-2" v-model="situationJuridique.cedant_dirigeant_has_mandat"
                         :disabled="!cedantCanModified"
                         @change="modifySituationJuridique">
                  <label class="gavc-label" for="situationJuridique.cedant_dirigeant_has_mandat-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>
            <p v-if="!situationJuridique.cedant_dirigeant_has_mandat" class="gavc-chapo gavc-text-rose mt-3">
              <strong>Attention</strong>,
              <span v-if="userGroupContains(['CEDANT'])">vous devez disposer de ce mandat pour continuer</span>
              <span v-if="userGroupContains(['REPRENEUR'])">le cédant n'a pas déclaré disposer du mandat nécessaire pour procéder à la cession</span>
            </p>
          </div>

          <hr>

          <h3 class="gavc-h3 gavc-text-green mt-4"> Lettre d'intérêt signée </h3>
          <p>
            Documents à charger : <strong>Lettre d'intérêt signée</strong>
          </p>
          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'situationJuridique')"
            @document-download="downloadAuditDocument($event, 'situationJuridique')"
            @document-uploaded="createAuditDocument($event, 'LOI', situationJuridique.id, 'situationJuridique')"
            @item-validated="validateAuditItem($event, 'situationJuridique', true)"
            @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
            :items="filterDocuments('LOI', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'LOI', situationJuridique.id, 'situationJuridique')"
            type="LOI"
          />

          <div v-if="!situationJuridique.has_cedant_validated" class="row">
            <div class="col-12 text-right">
              <button
                v-if="userGroupContains(['CEDANT'])"
                class="gavc-btn"
                @click="modifySituationJuridique"
                :disabled="$v.situationJuridique.cedant_email.$invalid || $v.situationJuridique.cedant_address.$invalid
                           || $v.situationJuridique.cedant_birthdate.$invalid || $v.situationJuridique.cedant_birthplace.$invalid ||
                           (!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique && !situationJuridique.cedant_dirigeant_has_mandat)">
                  Mettre à jour vos informations
              </button>
              <button
                v-if="userGroupContains(['CEDANT']) && isSituationJuridiqueValide"
                class="gavc-btn"
                @click="validateSituationJuridique(true)"
                :disabled="!situationJuridique.audit.candidature.offre_cession.entreprise_cedee.cedant.is_personne_physique && !situationJuridique.cedant_dirigeant_has_mandat">
                  Valider cette phase
              </button>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12 text-right">
              <button
                v-if="userGroupContains(['CEDANT'])"
                class="gavc-btn gavc-btn--bone"
                @click="validateSituationJuridique(false)"
              >
                  Invalider cette phase
              </button>
            </div>
          </div>


        </div>
        <div class="col-12 col-xl-6 pl-4" style="border-left: solid; border-width: 1px; border-color: #BCBDBC;">
          <h2 class="gavc-h2 gavc-text-tory text-center mb-4">
            Le repreneur
            <span v-if="userGroupContains(['REPRENEUR'])"> (à compléter)</span>
            <span v-if="userGroupContains(['CEDANT'])"> (à valider)</span>
          </h2>

          <div v-if="userGroupContains(['CEDANT'])">
            <div class="gavc-field">
              <label class="gavc-label" for="situationJuridique-cedant_comment-input">
                <strong class="gavc-text-green">En tant que cédant</strong>, vous pouvez laisser un commentaire au repreneur via le champ ci-dessous
              </label>
              <textarea
                class="gavc-textarea"
                id="situationJuridique-cedant_comment-input"
                maxlength="1000"
                rows="5"
                @change="modifySituationJuridique"
                placeholder="Je laisse mon commentaire au repreneur"
                type="text"
                v-model="situationJuridique.cedant_comment">
              </textarea>
            </div>
          </div>

          <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="situationJuridique.cedant_comment">
            <strong> Le cédant vous a laissé un commentaire </strong> <br>
            <span style="white-space: pre-line"> {{situationJuridique.cedant_comment}} </span>
          </div>

          <auditExplanation
            v-if="userGroupContains(['REPRENEUR'])"
            :isCedant="false"
            :isRepreneur="true"
          />

          <p class="gavc-p" v-else>
              Veuillez trouver ci-dessous les informations renseignées et documents
              chargés du repreneur
          </p>

          <div v-if="!situationJuridique.audit.candidature.repreneur.is_personne_physique">
            <h3 class="gavc-h3 gavc-text-tory"> La société </h3>
            <p>
              Documents à charger : <strong>Kbis et statuts de votre entreprise</strong>
            </p>
            <auditItem
              :disabled="!repreneurCanModified"
              @document-deleted="deleteAuditItem($event, 'situationJuridique')"
              @document-download="downloadAuditDocument($event, 'situationJuridique')"
              @document-uploaded="createAuditDocument($event, 'KBIS_STATUS', situationJuridique.id, 'situationJuridique')"
              @item-validated="validateAuditItem($event, 'situationJuridique', true)"
              @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
              :items="filterDocuments('KBIS_STATUS', 'REPRENEUR')"
              @justificatif-added="createAuditItemWithoutDocument($event, 'KBIS_STATUS', situationJuridique.id, 'situationJuridique')"
              type="KBIS_STATUS"
            />

          </div>

          <h3 v-if="!situationJuridique.audit.candidature.repreneur.is_personne_physique" class="gavc-h3 gavc-text-tory"> Le dirigeant </h3>
          <h3 v-else class="gavc-h3 gavc-text-tory"> Le repreneur </h3>
          <p>
            Documents à charger : <strong>Carte d'identité ou passeport valide</strong>
            <span v-if="!situationJuridique.audit.candidature.repreneur.is_personne_physique"> du dirigeant</span>
            <span v-else> du repreneur</span>
          </p>

          <auditItem
            :disabled="!repreneurCanModified"
            @document-deleted="deleteAuditItem($event, 'situationJuridique')"
            @document-download="downloadAuditDocument($event, 'situationJuridique')"
            @document-uploaded="createAuditDocument($event, 'CNI', situationJuridique.id, 'situationJuridique')"
            @item-validated="validateAuditItem($event, 'situationJuridique', true)"
            @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
            :items="filterDocuments('CNI', 'REPRENEUR')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'CNI', situationJuridique.id, 'situationJuridique')"
            type="CNI"
          />

          <div class="row">
            <div class="col-12 col-md-6">
              <div
                class="gavc-field"
                v-bind:class="{ 'is-error': $v.situationJuridique.repreneur_email.$dirty ? $v.situationJuridique.repreneur_email.$error : null  }">
                <label class="gavc-label" for="situationJuridique-repreneur_email-group">
                  Email :
                  <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique-repreneur_email-group"
                  maxlength="150"
                  @input="$v.situationJuridique.repreneur_email.$touch()"
                  type="email"
                  placeholder="jon@donym.fr"
                  v-model="situationJuridique.repreneur_email"
                  :disabled="!repreneurCanModified"
                >
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="gavc-field"
                v-bind:class="{ 'is-error': $v.situationJuridique.repreneur_address.$dirty ? $v.situationJuridique.repreneur_address.$error : null  }">
                <label class="gavc-label" for="situationJuridique-repreneur_address-group">
                    Adresse
                    <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique.repreneur_address-group"
                  maxlength="250"
                  @input="$v.situationJuridique.repreneur_address.$touch()"
                  type="text"
                  v-model="situationJuridique.repreneur_address"
                  :disabled="!repreneurCanModified"
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="gavc-field mt-3">
                <label class="gavc-label" for="situationJuridique.repreneur_birthdate-input">
                  Date de naissance
                  <span class="gavc-required"> * </span>
                </label>
                <div class="gavc-field gavc-field--icon">
                  <input
                    @input="$v.situationJuridique.repreneur_birthdate.$touch()"
                    class="gavc-input gavc-input--icon gavc-input--calendar"
                    id="situationJuridique.repreneur_birthdate-input"
                    type="date"
                    v-model="situationJuridique.repreneur_birthdate"
                    :disabled="!repreneurCanModified"
                  >
                  <i class="gavc-icon gavc-icon-calendar" ></i>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                class="gavc-field mt-3"
                v-bind:class="{ 'is-error': $v.situationJuridique.repreneur_birthplace.$dirty ? $v.situationJuridique.repreneur_birthplace.$error : null  }">
                <label class="gavc-label" for="situationJuridique.repreneur_birthplace-group">
                    Lieu de naissance
                    <span class="gavc-required"> * </span>
                </label>
                <input
                  class="gavc-input"
                  id="situationJuridique.repreneur_birthplace-group"
                  maxlength="250"
                  @input="$v.situationJuridique.repreneur_birthplace.$touch()"
                  type="text"
                  v-model="situationJuridique.repreneur_birthplace"
                  :disabled="!repreneurCanModified"
                >
              </div>
            </div>
          </div>
          <div v-if="!situationJuridique.audit.candidature.repreneur.is_personne_physique">
            <p class="gavc-label mt-3">
              Vous disposez d’un mandat de l’instance statutaire compétente ou disposez du pouvoir statutaire pour procéder à la présente cession ?
              <span class="gavc-required"> * </span>
            </p>
            <div class="gavc-field">
              <div class="gavc-radio-checkbox gavc-radio-checkbox--is-vert">
                <div class="gavc-radio-icon">
                  <input
                    :value="true"
                    type="radio"
                    id="situationJuridique.repreneur_dirigeant_has_mandat-input-1"
                    name="situationJuridique.repreneur_dirigeant_has_mandat-input-1"
                    v-model="situationJuridique.repreneur_dirigeant_has_mandat"
                    :disabled="!repreneurCanModified"
                    @change="modifySituationJuridique"
                  >
                  <label class="gavc-label" for="situationJuridique.repreneur_dirigeant_has_mandat-input-1">
                    Oui
                  </label>
                </div>
                <div class="gavc-radio-icon">
                  <input
                    :value="false"
                    type="radio"
                    id="situationJuridique.repreneur_dirigeant_has_mandat-input-2"
                    name="situationJuridique.repreneur_dirigeant_has_mandat-input-2"
                    v-model="situationJuridique.repreneur_dirigeant_has_mandat"
                    :disabled="!repreneurCanModified"
                    @change="modifySituationJuridique"
                  >
                  <label class="gavc-label" for="situationJuridique.repreneur_dirigeant_has_mandat-input-2">
                    Non
                  </label>
                </div>
              </div>
            </div>
            <p v-if="!situationJuridique.repreneur_dirigeant_has_mandat" class="gavc-chapo gavc-text-rose mt-3">
              <strong>Attention</strong>,
              <span v-if="userGroupContains(['REPRENEUR'])">vous devez disposer de ce mandat pour continuer</span>
              <span v-if="userGroupContains(['CEDANT'])">le repreneur n'a pas déclaré disposer du mandat nécessaire pour procéder à la cession</span>
            </p>
          </div>

          <h3 class="gavc-h3 gavc-text-tory mt-4"> Lettre d'intérêt signée </h3>
          <p>
            Documents à charger : <strong>Lettre d'intérêt signée</strong>
          </p>
          <auditItem
            :disabled="!repreneurCanModified"
            @document-deleted="deleteAuditItem($event, 'situationJuridique')"
            @document-download="downloadAuditDocument($event, 'situationJuridique')"
            @document-uploaded="createAuditDocument($event, 'LOI', situationJuridique.id, 'situationJuridique')"
            @item-validated="validateAuditItem($event, 'situationJuridique', true)"
            @item-unvalidated="validateAuditItem($event, 'situationJuridique', false)"
            :items="filterDocuments('LOI', 'REPRENEUR')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'LOI', situationJuridique.id, 'situationJuridique')"
            type="LOI"
          />

          <div v-if="!situationJuridique.has_repreneur_validated" class="row">
            <div class="col-12 text-right">
              <button
                v-if="userGroupContains(['REPRENEUR']) && !situationJuridique.has_repreneur_validated"
                class="gavc-btn"
                @click="modifySituationJuridique"
                :disabled="$v.situationJuridique.repreneur_email.$invalid || $v.situationJuridique.repreneur_address.$invalid
                           || $v.situationJuridique.repreneur_birthdate.$invalid || $v.situationJuridique.repreneur_birthplace.$invalid ||
                           (!situationJuridique.audit.candidature.repreneur.is_personne_physique && !situationJuridique.repreneur_dirigeant_has_mandat)">
                  Mettre à jour vos informations
              </button>
              <button
                v-if="userGroupContains(['REPRENEUR']) && !situationJuridique.has_repreneur_validated && isSituationJuridiqueValide"
                class="gavc-btn"
                @click="validateSituationJuridique(true)"
                :disabled="!situationJuridique.audit.candidature.repreneur.is_personne_physique && !situationJuridique.repreneur_dirigeant_has_mandat">
                  Valider cette phase
              </button>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12 text-right">
              <button
                v-if="userGroupContains(['REPRENEUR'])"
                class="gavc-btn gavc-btn--bone"
                @click="validateSituationJuridique(false)"
              >
                  Invalider cette phase
              </button>
            </div>
          </div>



        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="situationJuridique.has_cedant_validated"
            text="Le cédant a validé toute la section"
          />
        </div>
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="situationJuridique.has_repreneur_validated"
            text="Le repreneur a validé toute la section"
          />
        </div>
      </div>

      <div class="mt-4 text-center" v-if="situationJuridique.has_repreneur_validated && situationJuridique.has_cedant_validated">
        <button
          class="gavc-btn"
          @click="$router.push(`/audit/entreprise-cedee/${situationJuridique.audit.id}`)">
              Étape validée - Accéder à l'étape suivante
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, minLength, maxLength, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'
import { isDate } from '@/validators/validators'

const apiConnectedService = new APIConnected()
const auditExplanation = () => import('@/components/audit/auditExplanation')
const auditItem = () => import('@/components/audit/auditItem')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const macroStepper = () => import('@/components/audit/macroStepper')
const validationCartouche = () => import('@/components/layout/validationCartouche')


export default {
  name: 'SituationJuridique',
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    macroStepper,
    validationCartouche
  },
  mixins: [
    auditItemsMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingSituationJuridique: false,
      isSituationJuridiqueValide: false,

      situationJuridique: {
        audit: {
          cedant_comment: "",
          repreneur_comment: "",
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  is_personne_physique: true,
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              is_personne_physique: true,
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],
        cedant_email: "",
        cedant_address: "",
        cedant_birthdate: "",
        cedant_birthplace: "",
        cedant_dirigeant_has_mandat: false,
        repreneur_email: "",
        repreneur_address: "",
        repreneur_birthdate: "",
        repreneur_birthplace: "",
        repreneur_dirigeant_has_mandat: false
      },
    }
  },
  computed: {
    repreneurCanModified () {
      return this.userGroupContains(['REPRENEUR']) && !this.situationJuridique.has_repreneur_validated
    },
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.situationJuridique.has_cedant_validated
    },

    hasValidatedSituationJuridique () {
      return (this.userGroupContains(['CEDANT']) && this.situationJuridique.has_cedant_validated) ||
             (this.userGroupContains(['REPRENEUR']) && this.situationJuridique.has_repreneur_validated)
    },
    token () {
      return this.$store.state.auth.token
    },
    isInvalidSituationJuridique () {
      if (this.$v.situationJuridique.$invalid) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.scrollToTop()
    this.getSituationJuridique()
  },
  watch: {
    'situationJuridique.cedant_dirigeant_has_mandat' () {
      if (!this.isGettingSituationJuridique) {
        this.modifySituationJuridique()
      }
    },
    'situationJuridique.repreneur_dirigeant_has_mandat' () {
      if (!this.isGettingSituationJuridique) {
        this.modifySituationJuridique()
      }
    }
  },

  validations: {
    situationJuridique: {
      cedant_email: {
        email,
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      cedant_address: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      cedant_birthdate: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      cedant_birthplace: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },

      repreneur_email: {
        email,
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      repreneur_address: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      repreneur_birthdate: {
        required,
        validDate(value) {
          return isDate(value)
        }
      },
      repreneur_birthplace: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
    }
  },
  methods: {
    filterDocuments (type, uploader) {
      return this.situationJuridique.items.filter(item => item.type === type && item.uploader === uploader)
    },
    documentModified () {
      this.getSituationJuridique()
    },
    getSituationJuridique () {
      this.isGettingSituationJuridique = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'situationJuridique', this.$route.params.situationJuridiqueId)
      .then((result) => {
        this.situationJuridique = result.data
        if (this.$v.situationJuridique.$invalid) {
          this.isSituationJuridiqueValide = false
        }
        else {
          this.isSituationJuridiqueValide = true
        }
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('SituationJuridique getSituationJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingSituationJuridique = true
      })
    },

    modifySituationJuridique () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'situationJuridique', this.$route.params.situationJuridiqueId, this.situationJuridique)
      .then((result) => {
        this.situationJuridique = result.data
        if (this.$v.situationJuridique.$invalid) {
          this.isSituationJuridiqueValide = false
        }
        else {
          this.isSituationJuridiqueValide = true
        }
        this.$bvToast.hide('notification-toast');
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('SituationJuridique modifyAuditSection API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateSituationJuridique (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, 'situationJuridique', this.$route.params.situationJuridiqueId, data)
      .then((result) => {
        this.situationJuridique = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Situation juridique validée',
            variant: 'success',
            solid: true
          })
        }
        else {
            this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé l'étape, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('SituationJuridique validateSituationJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
